import * as React from "react";
import debounce from "lodash.debounce";
import { StoreContext } from "../../context/store-context";
import { formatPrice } from "../../utils/format-price";
import styles from "./Styles.module.scss";
import { Delete } from "@material-ui/icons";

export function LineItem({ item }) {
  const { removeLineItem, checkout, updateLineItem } = React.useContext(
    StoreContext
  );
  const [quantity, setQuantity] = React.useState(item.quantity);

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  return (
    <tr>
      <td>
        <img
          src={item.variant.image.src}
          alt={item.variant.title}
          className={styles.product_image}
        />
      </td>
      <td>
        <h2 className={styles.title}>{item.title}</h2>
        <div className={styles.variant}>
          {item.variant.title === "Default Title" ? "" : item.variant.title}
        </div>
        <div className={styles.remove}>
          <button onClick={handleRemove}>
            <Delete /> Remove
          </button>
        </div>
      </td>
      <td className={styles.priceColumn}>{price}</td>
      <td className={styles.textRight}> {quantity} </td>
      <td className={styles.totals}>{subtotal}</td>
    </tr>
  );
}
