import React, { Fragment } from "react";
import { Layout } from "src/components";
import { CartSection } from "../components/CartSection/CartSection";
import { HeroTemplate } from "src/services/prismic/templates";
import { graphql } from "gatsby";

const Cart = ({ data: { prismicPage: page } }) => {
  return (
    <Fragment>
      <Layout>
        {page?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
        <CartSection />;
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "cart" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
        }
      }
    }
  }
`;

export default Cart;
